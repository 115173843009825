import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Pokemon from "../components/pokemon"


const PokemonListPage = ({ data, location }) => {
  const [search, setSearch] = React.useState("")
  const rawPokemons = data.allMarkdownRemark.edges
  const [pokemons, setPokemons] = React.useState(rawPokemons)
  const [toShow, setToShow] = React.useState(20)
  const [isLoading, setIsLoading] = React.useState(true)
  const siteTitle = data.site.siteMetadata.title

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  React.useLayoutEffect(
    () => {
      setPokemons(
        rawPokemons.filter(pokemon => {
          if (!search || search.length <= 2) {
            return true
          } else {
            return pokemon.node.frontmatter.name.toLowerCase().includes(search)
          }
        })
      )
    },
    [search]
  )

  const handleScroll = () => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight
    const body = document.body
    const html = document.documentElement
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )
    const windowBottom = windowHeight + window.pageYOffset
    if (windowBottom >= docHeight) {
      setToShow(ts => ts + 20)
    }
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Pokédex Pokémon GO"
        description="There are more than 600 Pokémon catchable in Pokémon GO! Discover everything you need to know about them in this Pokémon GO Pokédex."
        slug={location.pathname}
      />

      <h1>Pokédex Pokémon GO</h1>

      <h2>How many Pokemons are in Pokemon go?</h2>

      <p>
        There are currently more than 630 Pokémons in Pokémon GO. Some of them
        are only implemented in the game data and can not be catched yet.
      </p>
      <p>Here is a complete list of all the Pokémons from Pokémon GO!</p>


      <input
        value={search}
        onChange={e => setSearch(e.target.value)}
        style={{ width: "100%" }}
        placeholder="Search for a Pokemon..."
      />

      <div id="list" onScroll={handleScroll}>
        {pokemons
          .map(pokemon => {
            return (
              <Link
                key={`${pokemon.node.frontmatter.id}-${
                  pokemon.node.frontmatter.form
                }`}
                to={`/pokemon-go/${
                  pokemon.node.frontmatter.id
                }-${pokemon.node.frontmatter.name.toLowerCase()}/`}
              >
                <Pokemon pokemon={pokemon.node.frontmatter} />
              </Link>
            )
          })}
      </div>


      <h2>Does anyone have a full Pokedex in Pokemon go?</h2>
      <p>
        Noone has a complete pokedex as of now as some Pokemons haven't been
        released yet.
      </p>
      <p>Some of those Pokemons not in game are:</p>
      <ul>
        <li>
          <Link to="/pokemon-go/493-arceus/">Arceus</Link>
        </li>
        <li>
          <Link to="/pokemon-go/625-bisharp/">Bisharp</Link>
        </li>
        <li>
          <Link to="/pokemon-go/626-bouffalant/">Bouffalant</Link>
        </li>
        <li>
          <Link to="/pokemon-go/546-cottonee/">Cottonee</Link>
        </li>
        <li>
          Deerling
        </li>
        <li>
          <Link to="/pokemon-go/621-druddigon/">Druddigon</Link>
        </li>
        <li>
          <Link to="/pokemon-go/603-eelektrik/">Eelektrik</Link>
        </li>
        <li>
          <Link to="/pokemon-go/604-eelektross/">Eelektross</Link>
        </li>
        <li>
          <Link to="/pokemon-go/587-emolga/">Emolga</Link>
        </li>
        <li>
          <Link to="/pokemon-go/592-frillish/">Frillish</Link>
        </li>
        <li>
          <Link to="/pokemon-go/593-jellicent/">Jellicent</Link>
        </li>
        <li>
          <Link to="/pokemon-go/352-kecleon/">Kecleon</Link>
        </li>
        <li>
          Keldeo
        </li>
        <li>
          <Link to="/pokemon-go/552-krokorok/">Krokorok</Link>
        </li>
        <li>
          <Link to="/pokemon-go/553-krookodile/">Krookodile</Link>
        </li>
        <li>
          <Link to="/pokemon-go/636-larvesta/">Larvesta</Link>
        </li>
        <li>
          <Link to="/pokemon-go/542-leavanny/">Leavanny</Link>
        </li>
        <li>
          <Link to="/pokemon-go/490-manaphy/">Manaphy</Link>
        </li>
        <li>
          <Link to="/pokemon-go/630-mandibuzz/">Mandibuzz</Link>
        </li>
        <li>
          Meloetta
        </li>
        <li>
          <Link to="/pokemon-go/619-mienfoo/">Mienfoo</Link>
        </li>
        <li>
          <Link to="/pokemon-go/620-mienshao/">Mienshao</Link>
        </li>
        <li>
          <Link to="/pokemon-go/517-munna/">Munna</Link>
        </li>
        <li>
          <Link to="/pokemon-go/518-musharna/">Musharna</Link>
        </li>
        <li>
          <Link to="/pokemon-go/624-pawniard/">Pawniard</Link>
        </li>
        <li>
          <Link to="/pokemon-go/489-phione/">Phione</Link>
        </li>
        <li>
          <Link to="/pokemon-go/551-sandile/">Sandile</Link>
        </li>
        <li>
          Sawsbuck
        </li>
        <li>
          <Link to="/pokemon-go/540-sewaddle/">Sewaddle</Link>
        </li>
        <li>
          Shaymin
        </li>
        <li>
          <Link to="/pokemon-go/865-sirfetch’d/">Sirfetch'd</Link>
        </li>
        <li>
          <Link to="/pokemon-go/541-swadloon/">Swadloon</Link>
        </li>
        <li>
          <Link to="/pokemon-go/602-tynamo/">Tynamo</Link>
        </li>
        <li>
          <Link to="/pokemon-go/583-vanillish/">Vanillish</Link>
        </li>
        <li>
          <Link to="/pokemon-go/582-vanillite/">Vanillite</Link>
        </li>
        <li>
          <Link to="/pokemon-go/584-vanilluxe/">Vanilluxe</Link>
        </li>
        <li>
          <Link to="/pokemon-go/637-volcarona/">Volcarona</Link>
        </li>
        <li>
          <Link to="/pokemon-go/629-vullaby/">Vullaby</Link>
        </li>
        <li>
          <Link to="/pokemon-go/547-whimsicott/">Whimsicott</Link>
        </li>
        <li>
          <Link to="/pokemon-go/571-zoroark/">Zoroark</Link>
        </li>
        <li>
          <Link to="/pokemon-go/570-zorua/">Zorua</Link>
        </li>
      </ul>

      <h2>What is the rarest Pokemon in Pokemon go?</h2>
      <p>
        There are no clear winner for the Rarest Pokemon in Pokemon GO title.
        Togetic has been known for being extremely rare but we could also cite
        the EX raid Bosses or Exclusive event Pokémons (Customs and rare
        movesets).
      </p>

      <h2>What is 144 145 and 146 on the Pokedex?</h2>
      <p>Those three Pokémons are the Legedary Birds from Kanto: </p>
      <ul>
        <li>
          <Link to="/pokemon-go/144-articuno/">Articuno</Link>
        </li>
        <li>
          <Link to="/pokemon-go/145-zapdos/">Zapdos</Link>
        </li>
        <li>
          <Link to="/pokemon-go/146-moltres/">Moltres</Link>
        </li>
      </ul>
      <p>
        If you're wondering to answer to that question, use the search bar or
        scroll to the designated Pokemon!
      </p>


    </Layout>
  )
}

export default PokemonListPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___id], order: ASC }
      filter: { fileAbsolutePath: { regex: "//pokemon//" } }
    ) {
      edges {
        node {
          frontmatter {
            id
            name
            form
            type1
            type2
            maxCp
            image {
              publicURL
            }
          }
        }
      }
    }
  }
`
